<template>
  <div class="flex flex-col items-center justify-center text-center py-4 px-4 space-y-10">
    <div>
      <NuxtImg
          src="/images/404-not-found-featured-television.gif"
          width="300"
          height="300"
          alt="not-found"
          class="object-contain h-64 w-64"
      />
    </div>
    <div>
      <h1 class="text-4xl">{{ $t('404.title') }}</h1>
      <p class="font-medium text-primary">
        {{ $t('404.description') }}
      </p>
    </div>
    <div>
      <FormButton color="gray" @click="navigateTo('/')">
        <ArrowLeftIcon class="text-secondary h-5 w-5 mr-2"/>
        <span>{{ $t('app.back-to-home') }}</span>
      </FormButton>
    </div>
  </div>
</template>

<script setup>
import {ArrowLeftIcon} from "@heroicons/vue/24/outline";
const {t} = useI18n()

definePageMeta({
  middleware : 'guest'
})

useHead({
  title: t('404.seo-title')
});

useServerSeoMeta({
  robots: 'noindex, nofollow'
})

</script>