<template>
  <Suspense>

    <div class="content-wrapper">
      <component :is="pageComponent" :slug="route.params.slug" />
    </div>

    <template #fallback>
      <div class="h-screen max-h-[50vh] z-full flex items-center justify-center">
        <div class="flex items-center gap-2 relative">
          <div class="rounded-full animate-ping bg-primary h-3 w-3 inset-0"></div>
          <span class="font-medium text-primary">
        </span>
        </div>
      </div>
    </template>
  </Suspense>
</template>

<script setup>
const runtimeConfig = useRuntimeConfig()
const { locale } = useI18n()
const route = useRoute()

useHead({
  title: runtimeConfig.public.seo_title[locale.value] // TODO : app.vue'den alınacak
})

definePageMeta({
  middleware: 'restrict',
})

const pageComponent = shallowRef()

const headers = useRequestHeaders(['cookie'])
const {data: templateType, error} = await useAsyncData(`slugCheck::${route.params.slug}`, () => $fetch('/api/checkTemplate', {
  key : `slugCheck::${route.params.slug}`,
  method: 'GET',
  query: {
    slug: route.params.slug,
    lang: locale.value,
  }
}))

if (templateType.value === 'CATEGORY' || templateType.value === 'COLLECTION' ||templateType.value === 'BRAND') {
  pageComponent.value = resolveComponent('LayoutCategory');
} else if (templateType.value === 'PAGE') {
  pageComponent.value = resolveComponent('LayoutPage');
} else if (templateType.value === 'PRODUCT') {
  pageComponent.value = resolveComponent('LayoutProduct');
} else {
  pageComponent.value = resolveComponent('Layout404');
}

// onBeforeRouteUpdate(() => pageComponent.value = null)

</script>

<style scoped>

</style>