<script setup>
import LoadingIcon from "assets/icons/loading.svg";

defineProps(['setNextPageValue', 'status'])

const {items, currentPage, perPage, totalResults, totalPage, baseParams, orderBy, nextPage, prevPage, customData} = usePagination()
const loadMoreButton = ref(null)

onMounted(() => {

  // For infinite scroll
  const observer = new IntersectionObserver(([{isIntersecting, target}]) => {

    if (!isIntersecting || target.intersectionRatio <= 0 || !nextPage.value || !items.value.length) return;

    /**
     * Sayfa değişimi yapıldığında filtreleme var ise bazen 2. sayfayı yüklemeye çalışıyor.
     */
    baseParams.value.page = nextPage.value;

  }, {
    // Do not grow or shrink the root area
    rootMargin: "20px",
    // Threshold of 1.0 will fire callback when 100% of element is visible
    threshold: 0
  });

  observer.observe(loadMoreButton.value);

})
</script>

<template>
  <div class="absolute mt-10 w-full h-12 flex items-center justify-center px-4" ref="loadMoreButton">
    <FormButton color="primary" v-if="status === 'success' && nextPage && items.length > 0" @click.prevent="setNextPageValue">+ {{ $t('listing.show-more') }}</FormButton>
    <LoadingIcon class="w-8 h-8 animate-spin" v-else-if="status === 'pending'"/>
  </div>
</template>

<style scoped>

</style>