<template>
  <div class="relative px-2.5" v-if="htmlContent && htmlContent !== '<p>null</p>'">
    <div class="flex-1 my-6 overflow-y-scroll scrollbar-thumb-lightgray scrollbar-track-transparent scrollbar-thin custom-scrollbar !text-primary-500" :class="{'max-h-24' : !showMore}" v-html="htmlContent" ></div>
    <a href="javascript:;" class="absolute right-0 text-sm font-medium text-gray-500 hover:text-secondary" @click="showMore=!showMore">{{showMoreText}}</a>
  </div>
</template>

<script setup>

const { t } = useI18n()

const props = defineProps(['htmlContent'])
const showMore = ref(false)
const showMoreText = computed(() => !showMore.value ? `+ ${t('app.show-more')}` : `- ${t('app.show-less')}`)

</script>

<style>
h1, h2, h3, h4, h5, h6 {
  @apply !font-bold;
}
</style>
