<template>
    <div class="category-wrapper">
      <component :is="listComponent">

        <!-- Breadcrumbs Alanı -->
        <template #breadcrumbs>
          <SectionBreadcrumb class="mt-2.5 sm:mt-0" :withHome="true" v-if="!isHideBreadCrumbs"/>
        </template>

        <!-- HTML İçerik Alanı -->
        <template #htmlContentArea>
          <h1 class="text-primary" v-if="(templateType === 'BRAND' || templateType === 'COLLECTION') && meta?.title">
            {{meta.title}}
          </h1>
          <ToggleHtmlContent :html-content="meta.htmlContent" v-if="meta?.htmlContent"/>
        </template>

        <!-- Filtreleme Alanı -->
        <template #filter>
          <LayoutSectionCategoryFilter />
        </template>

        <template #header>
          <LayoutSectionCategoryHeader/>
        </template>

        <template #emptyArea>
          <div class="flex flex-col items-center justify-center text-center py-8 px-4" v-if="items.length === 0 && status === 'success'">
            <div class="mb-3">
              <NuxtImg src="/images/empty-illustration.svg" width="258" height="193" class="object-contain" :alt="$t('listing.no-result')"/>
            </div>
            <div class="mb-5 md:mb-10 ">
              <p class="text-primary">
                {{ $t('listing.no-result') }}
              </p>
            </div>
          </div>
        </template>

        <UIInfiniteScrollMore :setNextPageValue="setNextPageValue" :status="status" />

      </component>
    </div>

    <ClientOnly>
        <ScrollToTop/>
    </ClientOnly>
</template>

<script setup>
import collect from "collect.js";

const {$listen, $unlisten, $event} = useNuxtApp()
const route = useRoute()
const {isWholesaleUser} = await useCustomer()
const {gViewItemList} = useGoogleEvents()

const {isShowFilter, isB2BAccount, isHideBreadCrumbs} = useHelper()
const {data: templateType} = useNuxtData(`slugCheck::${route.params.slug}`);

const sidebarOpen = useState("sidebarOpen", () => false);

const storeConfig = useStoreConfig()
const { themeComponents, b2bCategoryTemplate } = storeToRefs(storeConfig)
const searchListType = useState("searchListType", () => b2bCategoryTemplate.value === 'list' ? 1 : 2)

const listingTemplate = shallowRef();
switch(unref(themeComponents)?.listing_component) {
    case '2':
        listingTemplate.value = await resolveComponent('LazyTemplatesListingCategory2')
        break;
    case '3':
        listingTemplate.value = await resolveComponent('LazyTemplatesListingCategory3')
        break;
    default :
        listingTemplate.value = await resolveComponent('LazyTemplatesListingCategory1');
}

const listComponent = computed(() => {
    if(b2bCategoryTemplate.value === 'list' && isWholesaleUser.value && searchListType.value === 1) {
      return resolveComponent('LazyLayoutSectionCategoryHorizontal')
    } else {
      return listingTemplate.value
    }
})

const {items, currentPage, perPage, totalResults, totalPage, baseParams, orderBy, nextPage, prevPage, customData} = usePagination()
const {filters, activeFilters, isSearchAction, selectedFilters, resetFilters, getFilterOptions} = useFilter()

await getFilterOptions()

perPage.value = 8;

const queryString = computed(() => {
    return {
        ...baseParams.value,
        ...activeFilters.value,
        slug: route.params.slug
    }
})

const qs = useState('queryStringKey', () => new URLSearchParams({
    ...baseParams.value,
    slug: route.params.slug
}).toString())

// Facets
const facets = useState('facets');
const categoryFacets = useState('categoryFacets');
const brandFacets = useState('brandFacets');
const sizeFacets = useState('sizeFacets');
const attributeFacets = useState('attributeFacets');
const attributeItemFacets = useState('attributeItemFacets');
const colorFacets = useState('colorFacets');
const minPriceFacet = useState('minPriceFacet');
const maxPriceFacet = useState('maxPriceFacet');
const colorLabel = useState('colorLabel');
const sizeLabel = useState('sizeLabel');

// Request
const headers = useRequestHeaders(['cookie'])
const {data, error, refresh, status} = await useAsyncData(
    qs.value,
    () => $fetch('/api/products', {
        method: 'GET',
        query: {
            ...queryString.value,
            sort: orderBy.value?.value
        },
        headers,
        async onResponse({ request, response, options }) {
          const data = response._data;
          if (!facets.value) {
            facets.value = unref(data)?.facets
            minPriceFacet.value = unref(data)?.facets?.minPrice
            maxPriceFacet.value = unref(data)?.facets?.maxPrice
          }

          categoryFacets.value = unref(data)?.facets?.category
          brandFacets.value = unref(data)?.facets?.brand
          sizeFacets.value = unref(data)?.facets?.size
          attributeFacets.value = unref(data)?.facets?.attribute
          attributeItemFacets.value = unref(data)?.facets?.attributeItem
          colorFacets.value = unref(data)?.facets?.color

          if ( unref(data).results.length ) {
            // TODO : BURADA SIKINTI VAR
            if (baseParams.value.page > 1) {
              unref(data).results.forEach((i) => items.value.push(i))
            } else {
              items.value = unref(data)?.results
            }
          } else if (baseParams.value.page === 1 && !unref(data).results.length) { // sayfa değişince ürün yok ise eski datayı temizle
              items.value = []
          }
        },
    }),
)

currentPage.value = data.value?.currentPage;
totalResults.value = data.value?.totalProducts;
totalPage.value = data.value?.totalPage;
customData.value = data.value?.head.customData

const googleItems = computed(() => {
  return collect(items.value).map((i) => {
    return {
      item_id : i.productId,
      item_name : i.selectedVariant?.title,
      item_brand : i.brandName
    }
  }).toArray()
})

// arama sayfasından geliniyorsa, true olan değeri false'a alıyoruz
isSearchAction.value = false;

const setNextPageValue = () => {
  if (!nextPage.value || !items.value.length) return;

  /**
   * Sayfa değişimi yapıldığında filtreleme var ise bazen 2. sayfayı yüklemeye çalışıyor.
   */
  baseParams.value.page = nextPage.value;
}

const initFilter = ref(false)
watch(activeFilters, (updatedValue) => {
    /* ilk yüklemede sorun yapıyordu bu yüzden ilk yüklemeyi burada aşmaya çalışıyoruz */
    if (initFilter.value) {

      items.value = [];
      baseParams.value.page = 1;

      // reset page data
      currentPage.value = data.value.currentPage;
      totalResults.value = data.value.totalProducts;

      refresh();
    }

    initFilter.value = true;
}, {deep: true, immediate : false})

watch(baseParams, (newBaseParams) => {
    refresh();
}, {deep: true})

watch([data], ([newData]) => {
  // reset page data
  currentPage.value = unref(data)?.currentPage;
  totalResults.value = unref(data)?.totalProducts;

  // arama sayfasından geliniyorsa, true olan değeri false'a alıyoruz
  isSearchAction.value = false;

}, {deep: true})

onBeforeRouteUpdate((to, from) => {
    if (to.path !== from.path) {
        baseParams.value.page = 1;
        items.value = [];
        resetFilters()

        // Sadece farklı bir sayfaya geçiş yapıldığı zaman filtre facet değerlerini güncelliyoruz
        $event('updateFilterFacets')

        initFilter.value = true;
    }
});

onBeforeRouteLeave((to, from) => {
  baseParams.value.page = 1;
  items.value = [];
  resetFilters()
})

const meta = useState(`meta::${route.params.slug}`, () => data.value.head)
const breadcrumbs = useState(`breadcrumbList::${route.params.slug}`, () => data.value.head.breadcrumbList)

// listen to an event
$listen('reloadPageCategoryPage', (e) => {
    refreshNuxtData(qs.value)
})

$listen('updateFilterFacets', (e) => {
    // Update facets
    facets.value = unref(data)?.facets
    categoryFacets.value = unref(data)?.facets?.category
    brandFacets.value = unref(data)?.facets?.brand
    sizeFacets.value = unref(data)?.facets?.size
    attributeFacets.value = unref(data)?.facets?.attribute
    attributeItemFacets.value = unref(data)?.facets?.attributeItem
    colorFacets.value = unref(data)?.facets?.color
    minPriceFacet.value = unref(data)?.facets?.minPrice
    maxPriceFacet.value = unref(data)?.facets?.maxPrice
    colorLabel.value = unref(data)?.options?.colorLabel
    sizeLabel.value = unref(data)?.options?.sizeLabel
})

// $listen('addWishlist', (e) => {
//     const selectedProduct = items.value.find(i => i.productId === e.productId)
//     selectedProduct.selectedVariant.isFavorited = e.status
// })

useHead({
    title: meta.value.title,
    meta: [
        {name: 'description', content: meta.value.description}
    ],
})

useSchemaOrg([
    defineWebPage({
        '@type': ['CollectionPage', 'SearchResultsPage'],
    })
])

onBeforeRouteLeave(() => {
    $unlisten('reloadPageCategoryPage')
})

onMounted(() => {
  minPriceFacet.value = data.value.facets.minPrice;
  maxPriceFacet.value = data.value.facets.maxPrice;

  setTimeout(() => initFilter.value = true, 300)
})

// Google view item list event
gViewItemList(meta.value.title, meta.value.title, googleItems.value)

</script>

<style>
#launcher {
    margin: 70px 20px !important;
}
</style>
