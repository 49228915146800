<template>
  <div class="container py-6 sm:py-12 sm:px-4">
    <h1 class="text-3xl sm:text-4xl font-bold mb-2.5" v-if="content?.pageTitle">{{ content.pageTitle }}</h1>

    <!-- Dinamik Form -->
    <div :class="['space-y-2.5', content?.config?.wrapper_css]" v-if="content.pageType === 'form_page'">
      <template v-for="form in forms">
        <div v-if="form.value === 'text'">
          <FormInput
              v-model="formValue[form.config.form_value]"
              :label="form.config.input_value"
              :is-required="form.config?.isRequired"
              :class="form?.config?.class"
          />
        </div>

        <div v-else-if="form.value === 'textarea'">
          <FormInput
              input-type="textarea"
              v-model="formValue[form.config.form_value]"
              :label="form.config.input_value"
              :is-required="form.config?.isRequired"
              :class="form?.config?.class"
          />
        </div>

        <div v-else-if="form.value === 'checkbox'">
          <span class="text-xs">{{form}}</span>
          <FormCheckbox v-model="formValue[form.config.form_value]" color="primary" :class="form?.config?.class">
            <p class="text-sm">
              {{ form.config.input_value }}
            </p>
          </FormCheckbox>
        </div>

        <div v-else-if="form.value === 'select'">
          <FormSelect
              v-model="formValue[form.config.form_value]"
              :label="form.config.input_value"
              :options="form.options"
              :is-required="form.config?.isRequired"
              :class="form?.config?.class"
          />
        </div>

        <div v-else-if="form.value === 'radio'">
          <FormLabel :is-required="form.config?.isRequired">{{ form.config.input_value }}</FormLabel>
          <div :class="form?.config?.class">
            <template v-for="option in form.options">
              <FormRadioInput
                  type="radio-selected"
                  :input-value="option.value"
                  :label="option.label"
                  v-model="formValue[form.config.form_value]"
                  :name="form.config.form_value"
                  :id="form.config.form_value+option.value"
              ></FormRadioInput>
            </template>
          </div>
        </div>

        <div v-else-if="form.value === 'h1'">
          <h1 :class="form?.config?.class">{{form.config.input_value}}</h1>
        </div>

        <div v-else-if="form.value === 'h2'">
          <h2 :class="form?.config?.class">{{form.config.input_value}}</h2>
        </div>

        <div v-else-if="form.value === 'h3'">
          <h3 :class="form?.config?.class">{{form.config.input_value}}</h3>
        </div>

        <div v-else-if="form.value === 'h4'">
          <h4 :class="form?.config?.class">{{form.config.input_value}}</h4>
        </div>

        <div v-else-if="form.value === 'h5'">
          <h5 :class="form?.config?.class">{{form.config.input_value}}</h5>
        </div>

        <div v-else-if="form.value === 'html'" :class="form?.config?.class" v-html="form.config.html_content"></div>

        <div v-else-if="form.value === 'paragraph'">
          <p :class="form?.config?.class">{{form.config.input_value}}</p>
        </div>

<!--        <div v-else>-->
<!--          <span class="text-xs">{{form}}</span>-->
<!--        </div>-->
      </template>

      <!-- Submit Button -->
      <div class="flex items-center justify-end">
        <FormButton color="primary" :loading="formLoading" @click.prevent="submitForm">
          {{ $t('app.submit') }}
        </FormButton>
      </div>
    </div>

    <div v-html="content?.pageContent"></div>
  </div>
</template>

<script setup>
const route = useRoute();
const headers = useRequestHeaders(['cookie'])
const meta = ref([]);
const formValue = ref({});
const formLoading = ref(false)
const {$showToast} = useNuxtApp();

// Fetch data
const {data: page, error} = await useAsyncData(`getPage::${route.params.slug}`, () => $fetch('/api/pages', {
  key : `getPage::${route.params.slug}`,
  query: {
    slug: route.params.slug
  },
  headers,
  async onResponse({response}) {


    if (!response._data?.head?.isIndexable) {
      meta.value.push({ name: 'robots', content: 'noindex,nofollow'})
    }

    if (response._data?.head?.description) {
      meta.value.push({ name: 'description', content: response._data.head.description })
    }
  }
}))

const pageId = computed(() => page.value?.payload?.id)

const submitForm = async () => {
  formLoading.value = true

  const data = await $fetch("/api/form", {
    method: "post",
    body: {
      ...formValue.value,
      id : pageId.value
    },
    onResponseError({ request, response, options, error }) {
      // Handle the response errors
      if (response._data.statusCode === 422) {
        $showToast(response._data.data, 'error')
      }
      // registerErrors.value = response._data.data.message;
      formLoading.value = false;
    },
  });

  if (data?.status === "success") {
    $showToast(data.message, data?.status)

    // Formu resetle
    setTimeout(() => {
      formValue.value = {}
    }, 2000)
  }

  formLoading.value = false
}


const content = computed(() => page.value?.payload)
const forms = computed(() => page.value?.payload?.config?.forms)
const pageTitle = computed(() => page.value?.head?.title ? page.value?.head?.title : content.value?.pageTitle)

useHead({
  title: pageTitle,
  meta: meta,
})

</script>